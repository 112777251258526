<template>
  <div>
    <v-alert type="success" border="left" v-if="step > 5">
      {{ $t('message.successful_process') }}
    </v-alert>
    <v-stepper v-model="step" v-if="step <= 5">
      <v-stepper-header>
        <v-stepper-step :complete="step > 1" step="1" color="teal">
          {{ $t('cards.terms_and_conditions') }}
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 2" step="2" color="teal">
          {{ $t('cards.personal_data') }}
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 3" step="3" color="teal">
          {{ $t('cards.contact_information') }}
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 4" step="4" color="teal">
          {{ $t('cards.characterization') }}
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 5" step="5" color="teal">
          {{ $t('cards.requirements') }}
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-content step="1">
        <p class="d-md-none subtitle-2">
          {{ $t('cards.terms_and_conditions') }}
        </p>
        <v-card outlined color="grey lighten-4" class="mb-10 px-5">
          <v-card-text class="ma-0 pa-0 py-5">
            {{ $t('cards.i_agree') }}:
          </v-card-text>
          <v-checkbox
            class="ma-0 pa-0"
            v-model="item.selected"
            :key="item.id"
            v-for="item in conditions"
          >
            <template v-slot:label>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on"> {{ item.name }}</span>
                </template>
                <span>{{ item.description }}</span>
              </v-tooltip>
              <a
                class="mx-2"
                target="_blank"
                :href="item.url"
                @click.stop
                v-if="item.url"
              >
                <v-btn icon small>
                  <v-icon small>mdi-open-in-new</v-icon>
                </v-btn>
              </a>
            </template>
          </v-checkbox>
        </v-card>
        <v-layout>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="step = 2"
            :disabled="
              conditions.filter(function (i) {
                return i.selected == true
              }).length < conditions.length
            "
          >
            {{ $t('operations.next') }}
          </v-btn>
        </v-layout>
      </v-stepper-content>

      <v-stepper-content step="2">
        <p class="d-md-none subtitle-2">
          {{ $t('cards.personal_data') }}
        </p>
        <v-card outlined color="grey lighten-4 text-center" class="mb-10 pa-5">
          <v-form ref="formPersonalData" v-model="isPersonalDataValid">
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  v-model="personal_data.identification_type_id"
                  :items="identification_types"
                  item-text="name"
                  item-value="id"
                  :rules="[ruleRequired]"
                  required
                >
                  <template v-slot:label>
                    {{ $t('fields.identification_type') }}
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  type="number"
                  v-model="personal_data.identification_number"
                  :rules="[ruleRequired, ruleMinimumLength]"
                  autocomplete="off"
                  required
                >
                  <template v-slot:label
                    >{{ $t('fields.identification_number') }}
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="personal_data.first_name"
                  :rules="[ruleRequired]"
                  autocomplete="off"
                  required
                >
                  <template v-slot:label
                    >{{ $t('fields.first_name') }}
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="personal_data.last_name"
                  :rules="[ruleRequired]"
                  autocomplete="off"
                  required
                >
                  <template v-slot:label
                    >{{ $t('fields.last_name') }}
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-menu
                  ref="picker"
                  v-model="date_menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="personal_data.birthday"
                      :rules="[ruleRequired]"
                      required
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template v-slot:label>
                        {{ $t('fields.birthday') }}
                      </template>
                      <template v-slot:append v-on="on">
                        <v-icon v-on="on"> mdi-calendar </v-icon>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="personal_data.birthday"
                    :active-picker.sync="activePicker"
                    locale="es-co"
                    color="teal"
                    :max="new Date().toISOString().substr(0, 10)"
                    min="1900-01-01"
                    @change="set_date"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="years_old" readonly>
                  <template v-slot:label
                    >{{ $t('fields.years_old') }}
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
        <v-layout>
          <v-btn color="grey" dark @click="step = 1">
            {{ $t('operations.back') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="step = 3"
            :disabled="!isPersonalDataValid"
          >
            {{ $t('operations.next') }}
          </v-btn>
        </v-layout>
      </v-stepper-content>

      <v-stepper-content step="3">
        <p class="d-md-none subtitle-2">
          {{ $t('cards.contact_information') }}
        </p>
        <v-card outlined color="grey lighten-4 text-center" class="mb-10 pa-5">
          <v-form
            ref="formContactInformation"
            v-model="isContactInformationValid"
          >
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  v-model="municipality_id"
                  :items="municipalities"
                  item-text="name"
                  item-value="id"
                  :rules="[ruleRequired]"
                  required
                >
                  <template v-slot:label>
                    {{ $t('fields.municipality') }}
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  v-model="contact_information.sector_id"
                  :items="sectors"
                  item-text="name"
                  item-value="id"
                  :rules="[ruleRequired]"
                  required
                >
                  <template v-slot:label>
                    {{ $t('fields.sector') }}
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                  v-model="contact_information.neighborhood_id"
                  :items="
                    neighborhoods.filter(function (i) {
                      return i.municipality_id == municipality_id
                    })
                  "
                  item-text="name"
                  item-value="id"
                  :rules="[ruleRequired]"
                  required
                >
                  <template v-slot:label>
                    {{ $t('fields.neighborhood') }}
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="contact_information.address"
                  :rules="[ruleRequired]"
                  autocomplete="off"
                  required
                >
                  <template v-slot:label>{{ $t('fields.address') }} </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  type="number"
                  v-model="contact_information.phone"
                  :rules="[ruleRequired, rulePhoneFormat]"
                  autocomplete="off"
                  required
                >
                  <template v-slot:label>{{ $t('fields.phone') }} </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="contact_information.email"
                  :rules="[ruleRequired, ruleEmailFormat]"
                  autocomplete="off"
                  required
                >
                  <template v-slot:label>{{ $t('fields.email') }} </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" v-if="years_old <= 14">
                <v-text-field
                  v-model="contact_information.tutor_name"
                  :rules="[ruleRequired]"
                  autocomplete="off"
                  required
                >
                  <template v-slot:label
                    >{{ $t('fields.tutor_name') }}
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" v-if="years_old <= 14">
                <v-text-field
                  type="number"
                  v-model="contact_information.tutor_phone"
                  :rules="[ruleRequired, rulePhoneFormat]"
                  autocomplete="off"
                  required
                >
                  <template v-slot:label
                    >{{ $t('fields.tutor_phone') }}
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
        <v-layout>
          <v-btn color="grey" dark class="mx-2" @click="step = 2">
            {{ $t('operations.back') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="step = 4"
            :disabled="!isContactInformationValid"
          >
            {{ $t('operations.next') }}
          </v-btn>
        </v-layout>
      </v-stepper-content>

      <v-stepper-content step="4">
        <p class="d-md-none subtitle-2">
          {{ $t('cards.characterization') }}
        </p>
        <v-card outlined color="grey lighten-4" class="mb-10 pa-5">
          <v-form ref="formCharacterization" v-model="isCharacterizationValid">
            <v-row class="py-3">
              <v-col cols="12" sm="12" md="12">
                <p class="text-left text--secondary ma-0 pa-0">
                  <strong>{{ $t('cards.is_born_sex') }}</strong>
                </p>
                <v-select
                  v-model="personal_data.sex_id"
                  :items="sexes"
                  item-text="name"
                  item-value="id"
                  :rules="[ruleRequired]"
                  required
                >
                  <template v-slot:label>
                    {{ $t('fields.sex') }}
                  </template>
                </v-select>
              </v-col>
            </v-row>

            <v-row class="py-3">
              <v-col cols="12" sm="12" md="12">
                <p class="text-left text--secondary pa-0 ma-0">
                  <strong>{{ $t('cards.is_gender_identity') }}</strong>
                </p>
                <v-select
                  v-model="gender_identity"
                  :items="gender_identities"
                  item-text="name"
                  item-value="id"
                  :rules="[ruleRequired]"
                  required
                >
                  <template v-slot:label>
                    {{ $t('fields.gender_identity') }}
                  </template>
                </v-select>
              </v-col>
            </v-row>

            <v-row class="py-3">
              <v-col cols="12" sm="12" md="12">
                <p class="text-left text--secondary pa-0 ma-0">
                  <strong>{{ $t('cards.is_sexual_orientation') }}</strong>
                </p>
                <v-select
                  v-model="sexual_orientation"
                  :items="sexual_orientations"
                  item-text="name"
                  item-value="id"
                  :rules="[ruleRequired]"
                  required
                >
                  <template v-slot:label>
                    {{ $t('fields.sexual_orientation') }}
                  </template>
                </v-select>
              </v-col>
            </v-row>

            <v-row class="py-3">
              <v-col cols="12" sm="12" md="12">
                <p class="text-left text--secondary pa-0 ma-0">
                  <strong>{{ $t('cards.is_ethnic_group') }}</strong>
                </p>
                <v-select
                  v-model="ethnic_group"
                  :items="ethnic_groups"
                  item-text="name"
                  item-value="id"
                  :rules="[ruleRequired]"
                  required
                >
                  <template v-slot:label>
                    {{ $t('fields.ethnic_group') }}
                  </template>
                </v-select>
              </v-col>
            </v-row>

            <v-row class="py-3">
              <v-col cols="12" sm="12" md="12">
                <p class="text-left text--secondary pa-0 ma-0">
                  <strong>{{ $t('cards.is_handicap') }}</strong>
                </p>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-radio-group
                      v-model="is_handicap"
                      :rules="[ruleRequired]"
                      required
                      row
                    >
                      <v-radio :value="1">
                        <template v-slot:label>
                          {{ $t('boolean.yes') }}
                        </template>
                      </v-radio>
                      <v-radio :value="-1">
                        <template v-slot:label>
                          {{ $t('boolean.no') }}
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" sm="6" md="8">
                    <v-select
                      v-if="is_handicap == 1"
                      v-model="handicap"
                      :items="handicaps"
                      item-text="name"
                      item-value="id"
                      :rules="[ruleRequired]"
                      required
                    >
                      <template v-slot:label>
                        {{ $t('fields.handicap') }}
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row class="py-3">
              <v-col cols="12" sm="12" md="12">
                <p class="text-left text--secondary pa-0 ma-0">
                  <strong>{{ $t('cards.is_victim') }}</strong>
                </p>
                <v-radio-group
                  v-model="is_victim"
                  :rules="[ruleRequired]"
                  required
                  row
                >
                  <v-radio :value="1">
                    <template v-slot:label>
                      {{ $t('boolean.yes') }}
                    </template>
                  </v-radio>
                  <v-radio :value="-1">
                    <template v-slot:label>
                      {{ $t('boolean.no') }}
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-col>
            </v-row>

            <v-row class="py-3">
              <v-col cols="12" sm="12" md="12">
                <p class="text-left text--secondary pa-0 ma-0">
                  <strong>{{ $t('cards.is_householder') }}</strong>
                </p>
                <v-radio-group
                  v-model="is_householder"
                  :rules="[ruleRequired]"
                  required
                  row
                >
                  <v-radio :value="1">
                    <template v-slot:label>
                      {{ $t('boolean.yes') }}
                    </template>
                  </v-radio>
                  <v-radio :value="-1">
                    <template v-slot:label>
                      {{ $t('boolean.no') }}
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
        <v-layout>
          <v-btn color="grey" dark class="mx-2" @click="step = 3">
            {{ $t('operations.back') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="step = 5"
            :disabled="!isCharacterizationValid"
          >
            {{ $t('operations.next') }}
          </v-btn>
        </v-layout>
      </v-stepper-content>

      <v-stepper-content step="5">
        <input
          ref="uploader"
          class="d-none"
          type="file"
          accept=".pdf,.doc,.docx,.png,.jpg"
          @change="onFileChanged"
        />
        <v-alert
          type="info"
          color="grey"
          border="left"
          v-if="requirements.length <= 0"
        >
          {{ $t('message.no_requirements') }}
        </v-alert>
        <p class="d-md-none subtitle-2">
          {{ $t('cards.requirements') }}
        </p>
        <v-card
          outlined
          color="grey lighten-4 text-center"
          class="mb-12 pa-5"
          v-if="requirements.length > 0"
        >
          <v-data-table
            :headers="headers"
            :items="requirements"
            :disable-pagination="true"
            :hide-default-footer="true"
          >
            <template v-slot:loading>{{ $t('operations.loading') }} </template>
            <template v-slot:[`item.options`]="{ index }">
              <div>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue-grey lighten-2 dark"
                  class="mx-1"
                  dark
                  small
                  @click="uploadFile(index)"
                  >{{ $t('operations.upload') }}
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card>
        <v-layout>
          <v-btn color="grey" dark class="mx-2" @click="step = 4">
            {{ $t('operations.back') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="finalize()"
            :disabled="
              requirements.filter(function (i) {
                return i.file == undefined
              }).length > 0
            "
          >
            {{ $t('operations.finalize') }}
          </v-btn>
        </v-layout>
      </v-stepper-content>
    </v-stepper>
  </div>
</template>


<script>
export default {
  name: 'CitizenEnroll',
  components: {},
  props: ['requirements', 'conditions', 'bucketName', 'folderName'],
  data: () => ({
    step: 1,
    url: null,
    personal_data: {},
    contact_information: {},
    municipalities: [],
    neighborhoods: [],
    sectors: [],
    ethnic_groups: [],
    identification_types: [],
    sexes: [],
    is_victim: null,
    is_householder: null,
    gender_identity: null,
    ethnic_group: null,
    sexual_orientation: null,
    handicap: null,
    is_handicap: null,
    municipality_id: null,
    sexual_orientations: [],
    gender_identities: [],
    handicaps: [],
    agree: null,
    isPersonalDataValid: false,
    isContactInformationValid: false,
    isCharacterizationValid: false,
    ruleRequired: null,
    ruleMinimumLength: null,
    ruleEmailFormat: null,
    ruleDateFormat: null,
    ruleIsDate: null,
    rulePhoneFormat: null,
    years_old: null,
    date_menu: null,
    activePicker: null,
    index: null
  }),

  watch: {
    date_menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    }
  },

  created() {
    this.url = `public/citizen_enroll/`
    this.headers = [
      { text: this.$t('fields.requirement'), value: 'name' },
      { text: this.$t('fields.description'), value: 'description' },
      { text: this.$t('fields.filename'), value: 'filename' },
      { text: this.$t('fields.size'), value: 'size' },
      { text: this.$t('fields.options'), value: 'options', align: 'right' }
    ]

    this.getItems()

    // Rules
    this.ruleRequired = (v) => !!v || this.$t('rules.required')
    this.ruleMinimumLength = (v) =>
      (v && !(v.length < 5)) || this.$t('rules.minimum_length')
    this.ruleEmailFormat = (v) =>
      /.+@.+\..+/.test(v) || this.$t('rules.email_format')
    this.ruleIsDate = (v) =>
      (v && !isNaN(Date.parse(v))) || this.$t('rules.is_date')
    this.ruleDateFormat = (v) =>
      /^(\d{4})-(\d{2})-(\d{2})/.test(v) || this.$t('rules.date_format')
    this.rulePhoneFormat = (v) =>
      (v && !(v.length < 10)) || this.$t('rules.phone_format')
  },

  methods: {
    set_date(date) {
      this.$refs.picker.save(date)
      var diff_ms = Date.now() - new Date(date).getTime()
      var age_dt = new Date(diff_ms)
      this.years_old = Math.abs(age_dt.getUTCFullYear() - 1970)
    },

    getItems() {
      let url = this.url
      this.$http.get(url).then((request) => {
        this.identification_types = request.data.identification_types
        this.sexes = request.data.sexes
        this.gender_identities = request.data.gender_identities
        this.sexual_orientations = request.data.sexual_orientations
        this.ethnic_groups = request.data.ethnic_groups
        this.handicaps = request.data.handicaps
        this.municipalities = request.data.municipalities
        this.sectors = request.data.sectors
        this.neighborhoods = request.data.neighborhoods

        this.sexes.push({
          id: -1,
          name: this.$t('cards.no_want_to_answer')
        })
        this.gender_identities.push({
          id: -1,
          name: this.$t('cards.no_want_to_answer')
        })
        this.sexual_orientations.push({
          id: -1,
          name: this.$t('cards.no_want_to_answer')
        })
        this.ethnic_groups.push({
          id: -1,
          name: this.$t('cards.no_ethnic_group')
        })
        this.municipalities.push({
          id: -1,
          name: this.$t('fields.other')
        })
        this.neighborhoods.push({
          id: -1,
          municipality_id: -1,
          name: this.$t('fields.other')
        })
      })
    },

    uploadFile(index) {
      this.index = index
      this.$refs.uploader.click()
    },

    onFileChanged(e) {
      var size = parseFloat(e.target.files[0].size / (1024 * 1024)).toFixed(2)
      var obj = Object.assign(this.requirements[this.index], {
        filename: e.target.files[0].name,
        size: `${size} MB`,
        file: e.target.files[0]
      })
      this.requirements.splice(this.index, 1, obj)
      this.$refs.uploader.value = null
    },

    finalize() {
      if (this.contact_information.neighborhood_id === -1) {
        this.contact_information.neighborhood_id = null
      }
      if (this.personal_data.sex_id === -1) {
        this.personal_data.sex_id = null
      }
      const data = new FormData()
      data.append('bucket_name', this.bucketName)
      data.append('folder_name', this.folderName)
      data.append('personal_data', JSON.stringify(this.personal_data))
      data.append(
        'contact_information',
        JSON.stringify(this.contact_information)
      )
      if (this.gender_identity !== -1) {
        data.append('gender_identity', this.gender_identity)
      }
      if (this.sexual_orientation !== -1) {
        data.append('sexual_orientation', this.sexual_orientation)
      }
      if (this.ethnic_group !== -1) {
        data.append('ethnic_group', this.ethnic_group)
      }
      if (this.is_handicap !== -1) {
        data.append('handicap', this.handicap)
      }
      if (this.is_victim !== -1) {
        data.append('is_victim', this.is_victim)
      }
      if (this.is_householder !== -1) {
        data.append('is_householder', this.is_householder)
      }
      let requirements = this.requirements.map(function (i) {
        return i.requirement_id
      })
      data.append('requirements', JSON.stringify(requirements))

      this.$http.post(this.url, data).then((request) => {
        this.$emit('registry-completed', request.data.citizen_id)
        this.step = 6
        //Upload requirements
        this.requirements.forEach((e) => {
          let rs = request.data.requirements.find(function (i) {
            return i.requirement_id === e.requirement_id
          })
          fetch(rs.url_upload, { method: 'PUT', body: e.file })
        })
      })
    }
  }
}
</script>

<style>
a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:active {
  text-decoration: underline;
}
</style>
